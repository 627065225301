@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);
* {
  font-family: 'Roboto', sans-serif;
}

*:focus {
  outline: none !important;
}

html {
  scroll-behavior: smooth;
}

/* CUSTOM RESETS */
img,
input,
textarea,
button,
.form,
.card,
.card-img,
.btn {
  border-radius: 0px !important;
}

/* CUSTOM LAYOUT COMPONENTS */

:root {
  --lightblue: #f6f9fc;
  --dark: #333940;
}

a,
a:hover {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

.bg-lightblue {
  background: #f6f9fc;
  background: var(--lightblue);
}

.bg-red {
  background: var(--red);
}

.text-red {
  color: var(--red);
}

.container-fluid-max {
  max-width: 1440px;
}

.cover {
  background: no-repeat center/cover;
}

.p-15 {
  padding: 15px;
}

.bg-dark-custom {
  background-color: #080808e6;
}

#about-us,
#our-services,
#contact-us {
  padding: 120px 0 80px 0;
}

/* CUSTOM CSS BOOTSTRAP */

input:focus,
textarea:focus,
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #about-us,
  #our-services,
  #contact-us {
    padding: 80px 0 40px 0;
  }
}

img {
  width: 200px;
}

.scroll .page-header {
  background: var(--dark);
}

.page-header nav {
  -webkit-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
}

.page-header .navbar .navbar-nav a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}

.page-header .navbar .navbar-nav a:hover {
  font-size: 18px;
  font-weight: 400;
  border-bottom: solid #fff;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.page-header .navbar .navbar-nav a:hover {
  color: #fff;
}

.page-header .navbar-toggler {
  border-color: var(--white);
}

@media screen and (max-width: 991px) {
  .page-header {
    background: var(--dark);
  }
}

.scroll .hero {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.hero {
  background-attachment: fixed;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  background-image: url(/static/media/bg.min.c735ad21.jpg);
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(0, rgba(0, 0, 0, 0.5)),
    color-stop(50%, rgba(0, 0, 0, 0.3)),
    to(rgba(0, 0, 0, 0.1))
  );
  background: linear-gradient(
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.hero .container {
  z-index: 10;
}

.banner-title {
  font-size: 70px;
  color: #fff;
  font-weight: 800;
}

.banner-title span {
  color: #d9cb04;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .banner-title {
    font-size: 50px;
    font-weight: 600;
  }
}

.about-title {
  color: #a2dca1;
  text-shadow: 1px 2px rgb(100, 100, 100);
  font-size: 70px;
  font-weight: 800;
  margin: 0 auto;
}

.about-box {
  border-bottom: solid #a2dca1;
  padding: 15px;
}

.about-text {
  font-size: 18px;
  font-weight: 300;
}

.about-text span {
  font-size: 20px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .about-title {
    font-size: 40px;
    font-weight: 600;
  }
}

.services-title {
  color: #a2dca1;
  text-shadow: 1px 2px rgb(100, 100, 100);
  font-size: 70px;
  font-weight: 800;
  margin: 0 auto;
}

.services-box {
  border-bottom: solid #a2dca1;
  padding: 10px;
}

.services-box .card {
  border: none;
}

.services-text {
  font-size: 18px;
  font-weight: 300;
}

.services-text span {
  font-size: 20px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .services-title {
    font-size: 40px;
    font-weight: 600;
  }
}

.contact-title {
  color: #a2dca1;
  text-shadow: 1px 2px rgb(100, 100, 100);
  font-size: 70px;
  font-weight: 800;
  margin: 0 auto;
}

.contact-box {
  width: 100%;
  border-bottom: solid #a2dca1;
}

.contact-box form {
  border: none;
  margin-top: 30px;
}

.contact-box form .alert {
  border-radius: 0px;
  margin-top: 3px;
  padding: 4px 10px;
}

.contact-box form textarea {
  resize: none;
}

.alert {
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .contact-title {
    font-size: 40px;
    font-weight: 600;
  }
}

.alert {
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

.page-footer {
  background-color: #9b9a9b61;
}
.page-footer .footer-child p {
  font-size: 16;
  font-weight: 300;
}

.page-footer .footer-child p span {
  color: #333940;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .page-footer .footer-child {
    text-align: center;
  }
}

