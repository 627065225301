.services-title {
  color: #a2dca1;
  text-shadow: 1px 2px rgb(100, 100, 100);
  font-size: 70px;
  font-weight: 800;
  margin: 0 auto;
}

.services-box {
  border-bottom: solid #a2dca1;
  padding: 10px;
}

.services-box .card {
  border: none;
}

.services-text {
  font-size: 18px;
  font-weight: 300;
}

.services-text span {
  font-size: 20px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .services-title {
    font-size: 40px;
    font-weight: 600;
  }
}
