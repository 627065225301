img {
  width: 200px;
}

.scroll .page-header {
  background: var(--dark);
}

.page-header nav {
  transition: background 0.5s ease-in-out;
}

.page-header .navbar .navbar-nav a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}

.page-header .navbar .navbar-nav a:hover {
  font-size: 18px;
  font-weight: 400;
  border-bottom: solid #fff;
  transition: 0.1s;
}

.page-header .navbar .navbar-nav a:hover {
  color: #fff;
}

.page-header .navbar-toggler {
  border-color: var(--white);
}

@media screen and (max-width: 991px) {
  .page-header {
    background: var(--dark);
  }
}
