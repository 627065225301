.contact-title {
  color: #a2dca1;
  text-shadow: 1px 2px rgb(100, 100, 100);
  font-size: 70px;
  font-weight: 800;
  margin: 0 auto;
}

.contact-box {
  width: 100%;
  border-bottom: solid #a2dca1;
}

.contact-box form {
  border: none;
  margin-top: 30px;
}

.contact-box form .alert {
  border-radius: 0px;
  margin-top: 3px;
  padding: 4px 10px;
}

.contact-box form textarea {
  resize: none;
}

.alert {
  transition-duration: 2s;
  transition-delay: 1s;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .contact-title {
    font-size: 40px;
    font-weight: 600;
  }
}
