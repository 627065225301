.about-title {
  color: #a2dca1;
  text-shadow: 1px 2px rgb(100, 100, 100);
  font-size: 70px;
  font-weight: 800;
  margin: 0 auto;
}

.about-box {
  border-bottom: solid #a2dca1;
  padding: 15px;
}

.about-text {
  font-size: 18px;
  font-weight: 300;
}

.about-text span {
  font-size: 20px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .about-title {
    font-size: 40px;
    font-weight: 600;
  }
}
