.scroll .hero {
  transform: scale(0.98);
}

.hero {
  background-attachment: fixed;
  transition: transform 0.5s ease-in-out;
  background-image: url('../../assets/bg.min.jpg');
}

.hero::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.hero .container {
  z-index: 10;
}

.banner-title {
  font-size: 70px;
  color: #fff;
  font-weight: 800;
}

.banner-title span {
  color: #d9cb04;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .banner-title {
    font-size: 50px;
    font-weight: 600;
  }
}
