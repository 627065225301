@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

*:focus {
  outline: none !important;
}

html {
  scroll-behavior: smooth;
}

/* CUSTOM RESETS */
img,
input,
textarea,
button,
.form,
.card,
.card-img,
.btn {
  border-radius: 0px !important;
}

/* CUSTOM LAYOUT COMPONENTS */

:root {
  --lightblue: #f6f9fc;
  --dark: #333940;
}

a,
a:hover {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

.bg-lightblue {
  background: var(--lightblue);
}

.bg-red {
  background: var(--red);
}

.text-red {
  color: var(--red);
}

.container-fluid-max {
  max-width: 1440px;
}

.cover {
  background: no-repeat center/cover;
}

.p-15 {
  padding: 15px;
}

.bg-dark-custom {
  background-color: #080808e6;
}

#about-us,
#our-services,
#contact-us {
  padding: 120px 0 80px 0;
}

/* CUSTOM CSS BOOTSTRAP */

input:focus,
textarea:focus,
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  #about-us,
  #our-services,
  #contact-us {
    padding: 80px 0 40px 0;
  }
}
