.page-footer {
  background-color: #9b9a9b61;
}
.page-footer .footer-child p {
  font-size: 16;
  font-weight: 300;
}

.page-footer .footer-child p span {
  color: #333940;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .page-footer .footer-child {
    text-align: center;
  }
}
